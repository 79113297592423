:root {
  --page-background: #fbfbfb;
  --card-background: #fff;
  --padding-default: 1rem;
  --border-color: hsl(0, 0%, 90%);
  --shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  --sidebar-width: 250px;
  --focus-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --success-green: rgb(28, 184, 65);
  --warning-yellow: rgb(223, 117, 20);
  --error-red: rgb(202, 60, 60);
  --theme-color: hsl(212, 95%, 30%);
}
