/* You can add global styles to this file, and also import other style files */

html,
button,
input,
select,
textarea,
.pure-g [class *="pure-u"] {
  font-family: 'Roboto', sans-serif;
}

@import './assets/scss/custom.scss';
