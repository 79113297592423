@import './variables';

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--page-background);
}

.clickable {
  cursor: pointer;
}

.pure-img-responsive {
  max-width: 100%;
  height: auto;
}

.wrapper {
  padding: 2rem 1rem;

  .header {
    padding: 4rem 0 2rem;
    margin-bottom: 1rem;

    h2 {
      font-size: 32px;
      color: var(--theme-color);
      margin: 0 0 .25rem;
      padding: 0;
      font-weight: 400;
    }

    p {
      font-size: 18px;
      color: #4a4a4a;
      margin: 0;
      padding: 0;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  @media (min-width: 48em) {
    padding-inline: 2.5rem;
  }
}

.text-success {
  color: var(--success-green);
}

.text-warning {
  color: var(--warning-yellow);
}

.text-error {
  color: var(--error-red);
}


.circle-loader {
  --size: 80px;
  position: relative;
  pointer-events: none;
  min-height: var(--size);

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    height: calc(var(--size) * 0.5);
    width: calc(var(--size) * 0.5);
    margin-left: calc(var(--size) * -0.25);
    margin-top: calc(var(--size) * -0.25);
    border-radius: 50%;
    border: 2px solid #eee;
    border-top-color: var(--pmd-new-blue);
    animation: spinner 0.5s linear infinite;
  }

  &.mini {
    --size: 40px;
  }

}

@keyframes spinner {
  to {
    transform: rotate(360deg)
  }
}
